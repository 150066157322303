import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
    },

    description: {
      type: String,
    },

    image: {
      type: Object,
    },

    cta: {
      type: Object,
    },
  },
}
